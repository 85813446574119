import { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { useOutsideClick } from 'hooks/useOutsideClick';
import { Typography } from 'components/App/GlobalStyled';
import { EMAIL_SVG, SMS_SVG } from 'components/ui/_shared/SVG';
import { PopupButton } from 'components/ui/PopupButtton';
import { MessageModal } from 'pages/User/_shared/MessageModal';
import { formatPhoneNumber } from 'utils/prepare-data';
import { Wrap, BtnsWrapperStyled, MessageSelectorPopupStyled } from './styled';

const prepareRecipients = (recipients, selectedType) =>
  recipients
    ?.filter((r) => (selectedType === 'sms' ? r.phone : r.email))
    .map((r) => ({
      ...r,
      label:
        selectedType === 'sms'
          ? `${r.label}${r?.phone ? ` (+${formatPhoneNumber(r.phone)})` : ''}`
          : `${r.label}${r.email ? ` (${r.email})` : ''}`,
    }));

export const MessageSelectorPopup = ({
  disableAdding,
  recipients,
  position,
  list,
  onSubmitSms,
  onSubmitEmail,
  onClose,
  prepareRecipientsOnSubmit,
}) => {
  const ref = useRef();
  const theme = useTheme();
  const [selectedType, setSelectedType] = useState('');

  const preparedList = useMemo(() => prepareRecipients(list, selectedType));
  const preparedRecipients = useMemo(() =>
    prepareRecipients(recipients, selectedType)
  );

  const handleOutsideClick = () => {
    if (!selectedType) {
      onClose();
    }
  };

  useOutsideClick(ref, handleOutsideClick);

  const onButtonClick = (type) => {
    setSelectedType(type);
  };

  const onModalClose = () => {
    onClose();
    setSelectedType('');
  };

  const handleMessageSubmit = async (data) => {
    if (selectedType === 'sms') {
      await onSubmitSms(data);
    } else {
      await onSubmitEmail(data);
    }
  };

  return (
    <Wrap position={position}>
      <MessageSelectorPopupStyled
        ref={ref}
        hidden={selectedType}
      >
        <Typography
          fz="18px"
          fw="600"
        >
          Choose Communication Method
        </Typography>

        <Typography
          color={theme.gray600}
          m="0 0 20px"
        >
          Select whether to send the message via email or SMS.
        </Typography>

        <BtnsWrapperStyled>
          <PopupButton
            prependIcon={<SMS_SVG />}
            onClick={() => onButtonClick('sms')}
          >
            Sms
          </PopupButton>

          <PopupButton
            prependIcon={<EMAIL_SVG />}
            onClick={() => onButtonClick('email')}
          >
            Email
          </PopupButton>
        </BtnsWrapperStyled>
      </MessageSelectorPopupStyled>

      {selectedType && (
        <MessageModal
          disableAdding={disableAdding}
          selectedRecipients={preparedRecipients}
          recipientsList={preparedList}
          type={selectedType}
          onClose={onModalClose}
          onSubmit={handleMessageSubmit}
          prepareRecipientsOnSubmit={prepareRecipientsOnSubmit}
        />
      )}
    </Wrap>
  );
};

MessageSelectorPopup.propTypes = {
  disableAdding: PropTypes.bool,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  list: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  onSubmitSms: PropTypes.func,
  onSubmitEmail: PropTypes.func,
  onClose: PropTypes.func,
  prepareRecipientsOnSubmit: PropTypes.bool,
};
