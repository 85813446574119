import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Typography } from 'components/App/GlobalStyled';
import { RPagination } from 'components/RPagination';
import { RLoader } from 'components/RLoader';
import { RMask } from 'components/RMask';
import { SortingArrow } from './SortingArrow';
import {
  EmptyText,
  LoadingStyled,
  PaginationStyled,
  ScrollContainer,
  TBody,
  TCell,
  THead,
  TRow,
  Wrap,
} from './styled';

export const TableRefContext = createContext(null);

export function RTable({
  columns = [],
  onRowClick,
  data,
  rowsCount = 8,
  height = 510,
  cursiveRowField,
  listEmptyMessage,
  currentPage = 1,
  disablePagination,
  onPageChange,
  loading,
}) {
  const tableRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);

  const [sorting, setSorting] = useState({
    column: '',
    sortFunction: null,
    isAsc: true,
  });

  const sortedData = sorting.sortFunction
    ? [...data].sort((a, b) => sorting.sortFunction(a, b, sorting.isAsc))
    : data;

  const displayedData = sortedData.slice(
    (currentPage - 1) * rowsCount,
    currentPage * rowsCount
  );

  const contextValue = useMemo(
    () => ({ tableRef, tableWidth }),
    [tableRef, tableWidth]
  );

  useEffect(() => {
    if (tableRef.current) {
      setTableWidth(tableRef.current.offsetWidth);
    }
  }, []);

  return (
    <TableRefContext.Provider value={contextValue}>
      <Wrap
        ref={tableRef}
        $height={height}
      >
        {loading && (
          <RMask css={{ borderRadius: '12px', zIndex: 1 }}>
            <LoadingStyled>
              <RLoader />
            </LoadingStyled>
          </RMask>
        )}
        <ScrollContainer noScroll={!displayedData.length}>
          <THead>
            <TRow>
              {columns.map((cell) => (
                <TCell
                  key={`head-${cell.slug}`}
                  css={{ ...cell.style, ...cell.headerStyle }}
                >
                  {cell.title}

                  {cell.sortFunction && (
                    <SortingArrow
                      column={cell.slug}
                      isSelected={sorting.column === cell.slug}
                      sortFunction={cell.sortFunction}
                      setSorting={setSorting}
                    />
                  )}
                </TCell>
              ))}
            </TRow>
          </THead>

          <TBody>
            {displayedData.map((row) => (
              <TRow
                key={`row-${row.id}`}
                onClick={onRowClick ? () => onRowClick(row) : undefined}
                isClickable={!!onRowClick}
                isCursive={cursiveRowField && row[cursiveRowField]}
                css={row.style}
              >
                {columns.map((cell) => (
                  <TCell
                    key={`body-${cell.slug}`}
                    css={cell.style}
                  >
                    {cell.render ? cell.render(row) : row[cell.slug]}
                  </TCell>
                ))}
              </TRow>
            ))}

            {!displayedData.length ? (
              <EmptyText>{!loading && listEmptyMessage}</EmptyText>
            ) : null}
          </TBody>
        </ScrollContainer>

        {!disablePagination && (
          <PaginationStyled>
            <Typography color="#344054">
              {!!data?.length && (
                <>
                  Showing data {(currentPage - 1) * rowsCount + 1} to&nbsp;
                  {Math.min(currentPage * rowsCount, data.length)} of&nbsp;
                </>
              )}
              {data.length} entries
            </Typography>

            <RPagination
              current={currentPage}
              total={data.length}
              perPage={rowsCount}
              onChange={onPageChange}
            />
          </PaginationStyled>
        )}
      </Wrap>
    </TableRefContext.Provider>
  );
}

RTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      style: PropTypes.any,
      headerStyle: PropTypes.any,
      render: PropTypes.func,
      sortFunction: PropTypes.func,
    })
  ).isRequired,
  rowsCount: PropTypes.number,
  listEmptyMessage: PropTypes.string,
  onRowClick: PropTypes.func,
  height: PropTypes.number,
  cursiveRowField: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPage: PropTypes.number,
  disablePagination: PropTypes.bool,
  onPageChange: PropTypes.func,
  loading: PropTypes.bool,
};
