import { addDays, differenceInMinutes, format, parse } from 'date-fns';

import store from 'store';
import { appTimeFormat } from './const';

export const convertUserMilitaryTimeToUTC = (localTime) => {
  const userOffset = store.getState().general.profile.timezone.utc_diff;
  const utcDate = new Date(`1970-01-01T${localTime}:00.000Z`);

  utcDate.setUTCHours(utcDate.getUTCHours() - Number(userOffset));

  const hours = utcDate.getUTCHours().toString().padStart(2, '0');
  const minutes = utcDate.getUTCMinutes().toString().padStart(2, '0');
  const utcTime = `${hours}:${minutes}`;

  return utcTime;
};

export const convertUserDateToUTC = (localDate, localTime) => {
  const userOffset = store.getState().general.profile.timezone.utc_diff;
  const utcDateTime = new Date(`${localDate.slice(0, 10)}T${localTime}:00.000`);

  utcDateTime.setUTCHours(utcDateTime.getUTCHours() - Number(userOffset));

  return utcDateTime.toISOString().slice(0, 10);
};

export const formatLessonTime = (time) => {
  const parsedTime = parse(time, appTimeFormat.H_24_SECONDS, new Date());

  return format(parsedTime, appTimeFormat.AMPM_12H);
};

export const formatMilitaryToAMPM = (time) => {
  if (!time) return '';

  try {
    const parsedTime = parse(time, appTimeFormat.H_24, new Date());

    return format(parsedTime, appTimeFormat.AMPM_12H_UPPERCASE);
  } catch (e) {
    console.log(e);
    return '';
  }
};

export const calculateDuration = (startMilitaryTime, endMilitaryTime) => {
  const startDate = new Date(`1970-01-01T${startMilitaryTime}:00Z`);
  const endDate = new Date(`1970-01-01T${endMilitaryTime}:00Z`);
  const finalEndDate = startDate < endDate ? endDate : addDays(endDate, 1);

  return differenceInMinutes(finalEndDate, startDate);
};
