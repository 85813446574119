import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { RButtonIcon } from 'components/ui/RButtonIcon';
import { NumberBtns } from './NumberBtns';
import {
  Wrap,
  Label,
  LabelStatic,
  FieldWrap,
  HelperText,
} from '../_shared/styled';
import { CLOSED_EYE_SVG, EYE_SVG } from '../_shared/SVG';
import { FieldInput, FieldIcons } from './styled';

TextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  helperText: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  height: PropTypes.number,
  error: PropTypes.bool,
  labelStatic: PropTypes.bool,
  helperTextStatic: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  onEnterPress: PropTypes.func,
  showNumberBtns: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  readOnly: PropTypes.bool,
  AdditionalComponent: PropTypes.any,
  isBlackFont: PropTypes.bool,
};

export function TextField({
  name,
  label,
  type = 'text',
  value,
  helperText,
  height = 48,
  labelStatic = true,
  error = false,
  helperTextStatic = true,
  placeholder,
  fullWidth = false,
  disabled = false,
  hidden = false,
  variant,
  onChange = () => {},
  onEnterPress = null,
  showNumberBtns,
  min,
  max,
  step = 1,
  readOnly = false,
  AdditionalComponent,
  isBlackFont,
}) {
  const [fieldType, setFieldType] = useState(type);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    setFieldType(type);
  }, [type]);

  const onFocus = () => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  const handlePassword = () => {
    const type = fieldType === 'password' ? 'text' : 'password';
    setFieldType(type);
  };

  const isFilled = useMemo(() => {
    return !!value || value.length > 0;
  }, [value]);

  const handleKeyPress = (event) => {
    if (onEnterPress && event.key === 'Enter') {
      onEnterPress();
    }
  };

  return (
    <Wrap
      fullWidth={fullWidth}
      disabled={disabled}
      isBlackFont={isBlackFont}
    >
      {label && labelStatic ? (
        <LabelStatic
          htmlFor={name}
          error={error}
          disabled={disabled}
          focused={isFocus}
        >
          {label}
        </LabelStatic>
      ) : null}
      <FieldWrap>
        {!labelStatic && (
          <Label
            htmlFor={name}
            focus={isFocus}
            activated={isFilled}
            error={error}
            disabled={disabled}
          >
            {label}
          </Label>
        )}
        <FieldInput
          id={name}
          type={fieldType}
          height={height}
          appendIcon={fieldType === 'password'}
          error={error}
          placeholder={placeholder}
          onChange={disabled || hidden ? undefined : onChange}
          onKeyDown={handleKeyPress}
          onFocus={onFocus}
          onBlur={onBlur}
          activated={isFilled}
          focus={isFocus}
          disabled={disabled}
          hidden={hidden}
          readOnly={readOnly || disabled || hidden}
          labelStatic={labelStatic}
          value={value}
          variant={variant}
          isBlackFont={isBlackFont}
        />
        <FieldIcons disabled={disabled}>
          {type === 'password' && (
            <RButtonIcon
              onClick={handlePassword}
              asButton={!disabled}
            >
              {fieldType === 'password' ? <CLOSED_EYE_SVG /> : <EYE_SVG />}
            </RButtonIcon>
          )}

          {fieldType === 'number' && showNumberBtns && (
            <NumberBtns
              value={value}
              onChange={onChange}
              min={min}
              max={max}
              step={step}
            />
          )}
        </FieldIcons>

        {AdditionalComponent && <AdditionalComponent />}
      </FieldWrap>
      {helperTextStatic && <HelperText>{error && helperText}</HelperText>}
    </Wrap>
  );
}
