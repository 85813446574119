import { formatMilitaryToAMPM } from '../utils/times';

export const scheduleDefaultValuesModel = ({
  classroom_id,
  start,
  end,
  userLocalFormattedWeeklySchedule,
  isExcludeClosures,
}) => {
  const recurring = Object.values(userLocalFormattedWeeklySchedule).map(
    ({ day, start, end }) => {
      return {
        dayOfWeek: { label: day, value: day },
        startTime: { label: formatMilitaryToAMPM(start), value: start },
        endTime: { label: formatMilitaryToAMPM(end), value: end },
      };
    }
  );

  return {
    classRoomId: classroom_id,
    startDate: start,
    endDate: end,
    recurring,
    excludeClosures: isExcludeClosures,
  };
};
