import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useOutsideClick } from 'hooks/useOutsideClick';
import { RListIsEmpty } from 'components/RListIsEmpty';
import { CHECKMARK_SVG, DROPDOWN_CHEVRON_SVG } from '../_shared/SVG';
import {
  CheckMarkStyled,
  DropdownOptionPrependIcon,
  FieldDropdown,
  FieldWrap,
  HelperText,
  Label,
  LabelStatic,
  RootFieldDropdownOption,
  Wrap,
} from '../_shared/styled';
import { FieldIcons, FieldInput } from './styled';
import { RAvatar } from '../../RAvatar';

export const autoCompleteFieldVariants = {};

function AutocompleteField({
  name,
  label,
  placeholder,
  helperText,
  options = [],
  value = {},
  height = 48,
  labelStatic = true,
  error = false,
  fullWidth = false,
  disabled = false,
  readOnly = false,
  clearOnChoose = false,
  hideChevron = false,
  variant,
  selectedOptions = [],
  onChange = () => {},
  handleChoose = () => {},
  isBlackFont,
}) {
  const [dropdownIsOpened, setDropdownIsOpened] = useState(false);
  const ref = useRef(null);
  const [isFocus, setIsFocus] = useState(false);
  const [fieldValue, setFieldValue] = useState(value.label || '');

  useEffect(() => {
    if (Object.values(value).length > 0 && value.label !== fieldValue) {
      setFieldValue(value.label);
    }
  }, [value]);

  const onChangeFieldValue = (e) => {
    setFieldValue(e.target.value);
  };

  const onFocus = () => {
    setIsFocus(true);
    onOpenDropdown();
  };

  const onBlur = () => {
    if (!dropdownIsOpened) setIsFocus(false);
  };

  const onOpenDropdown = () => {
    setDropdownIsOpened(!dropdownIsOpened);
  };

  const onCloseDropdown = () => {
    setDropdownIsOpened(false);
    if (!fieldValue.length) onChange({ value: '', label: '' });
    else setFieldValue(value.label || '');
  };

  const onChooseOption = (option) => {
    onChange(option);
    setDropdownIsOpened(false);
    handleChoose(option);

    if (clearOnChoose) onChange({});
  };

  const isFilled = useMemo(() => {
    return fieldValue.length > 0;
  }, [fieldValue]);

  const filteredOptions = useMemo(() => {
    if (fieldValue.length) {
      return options.filter((option) => {
        return option.label.toLowerCase().includes(fieldValue.toLowerCase());
      });
    }
    return options;
  }, [fieldValue, options]);

  const renderOptions = () => {
    if (!filteredOptions.length) return <RListIsEmpty />;

    return filteredOptions.map((option) => (
      <RootFieldDropdownOption
        key={option.value}
        active={option.value === value.value}
        onClick={() => onChooseOption(option)}
      >
        {'path' in option && (
          <DropdownOptionPrependIcon>
            <RAvatar
              name={option?.label}
              thumb={option?.path}
              size={24}
            />
          </DropdownOptionPrependIcon>
        )}

        <span>{option.label}</span>

        {option.value === value.value ||
          (selectedOptions.some((o) => o.value === option.value) && (
            <CheckMarkStyled>
              <CHECKMARK_SVG />
            </CheckMarkStyled>
          ))}
      </RootFieldDropdownOption>
    ));
  };

  useOutsideClick(ref, () => {
    if (dropdownIsOpened) {
      onCloseDropdown();
    }
  });

  return (
    <Wrap
      fullWidth={fullWidth}
      ref={ref}
      isBlackFont={isBlackFont}
    >
      {label && labelStatic ? (
        <LabelStatic
          htmlFor={name}
          error={error}
          disabled={disabled}
        >
          {label}
        </LabelStatic>
      ) : null}
      <FieldWrap>
        {!labelStatic && (
          <Label
            htmlFor={name}
            focus={isFocus}
            activated={isFilled}
            error={error}
            disabled={disabled}
          >
            {label}
          </Label>
        )}
        <FieldInput
          id={name}
          type="text"
          height={height}
          error={error}
          value={fieldValue}
          activated={isFilled}
          focus={dropdownIsOpened}
          placeholder={placeholder}
          onChange={onChangeFieldValue}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          readOnly={readOnly}
          labelStatic={labelStatic}
          variant={variant}
          autoComplete="off"
          isBlackFont={isBlackFont}
        />
        {!hideChevron && (
          <FieldIcons
            disabled={disabled || readOnly}
            isOpened={dropdownIsOpened}
            onClick={onOpenDropdown}
          >
            <DROPDOWN_CHEVRON_SVG />
          </FieldIcons>
        )}
        {dropdownIsOpened && <FieldDropdown>{renderOptions()}</FieldDropdown>}
      </FieldWrap>
      <HelperText>{error && helperText}</HelperText>
    </Wrap>
  );
}

AutocompleteField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  height: PropTypes.number,
  labelStatic: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  clearOnChoose: PropTypes.bool,
  hideChevron: PropTypes.bool,
  variant: PropTypes.string,
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
  handleChoose: PropTypes.func,
  isBlackFont: PropTypes.bool,
};

export default AutocompleteField;
